exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-2-jsx": () => import("./../../../src/pages/index-2.jsx" /* webpackChunkName: "component---src-pages-index-2-jsx" */),
  "component---src-pages-index-3-jsx": () => import("./../../../src/pages/index-3.jsx" /* webpackChunkName: "component---src-pages-index-3-jsx" */),
  "component---src-pages-index-4-jsx": () => import("./../../../src/pages/index-4.jsx" /* webpackChunkName: "component---src-pages-index-4-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-farm-jsx": () => import("./../../../src/pages/our-farm.jsx" /* webpackChunkName: "component---src-pages-our-farm-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-templates-blog-details-jsx": () => import("./../../../src/templates/blog-details.jsx" /* webpackChunkName: "component---src-templates-blog-details-jsx" */),
  "component---src-templates-blog-list-jsx": () => import("./../../../src/templates/blog-list.jsx" /* webpackChunkName: "component---src-templates-blog-list-jsx" */),
  "component---src-templates-blogs-category-jsx": () => import("./../../../src/templates/blogs-category.jsx" /* webpackChunkName: "component---src-templates-blogs-category-jsx" */),
  "component---src-templates-blogs-tag-jsx": () => import("./../../../src/templates/blogs-tag.jsx" /* webpackChunkName: "component---src-templates-blogs-tag-jsx" */),
  "component---src-templates-equipment-detail-jsx": () => import("./../../../src/templates/equipment-detail.jsx" /* webpackChunkName: "component---src-templates-equipment-detail-jsx" */),
  "component---src-templates-equipment-listing-index-jsx": () => import("./../../../src/templates/equipment-listing/index.jsx" /* webpackChunkName: "component---src-templates-equipment-listing-index-jsx" */),
  "component---src-templates-equipment-listing-listing-by-categories-jsx": () => import("./../../../src/templates/equipment-listing/listingByCategories.jsx" /* webpackChunkName: "component---src-templates-equipment-listing-listing-by-categories-jsx" */),
  "component---src-templates-portfolio-details-jsx": () => import("./../../../src/templates/portfolio-details.jsx" /* webpackChunkName: "component---src-templates-portfolio-details-jsx" */),
  "component---src-templates-service-details-jsx": () => import("./../../../src/templates/service-details.jsx" /* webpackChunkName: "component---src-templates-service-details-jsx" */)
}

