import React from "react";

export default function Top({ data }) {
  return (
    <div className="header-top-bar top-bar-one dark-black-bg">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-12 col-md-12 col-6">
            <div className="top-bar-left d-flex align-items-center">
              <span className="text">{data?.welcome}</span>
              {/* <span className="lang-dropdown">
              <select className="wide">
                <option value={1}>English</option>
                <option value={2}>French</option>
              </select>
            </span> */}
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-6">
            <div className="top-bar-right">
              {data?.time && (
                <span className="text">
                  <i className="far fa-clock" />
                  Opening Hours : {data?.time}
                </span>
              )}
              <ul className="social-link">
                {data?.social.map((item, index) => (
                  <li key={index}>
                    <a href={item.url} target="_blank" title={item.url}>
                      <i className={`fab ${item.icon}`} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
