import React from "react";
import { Link } from "gatsby";
import { slug } from "../utils";

const MyLink = ({ text, linkType, link, onClick = null }) => {
  if (linkType === "internal") {
    return (
      <Link to={slug(link)} onClick={onClick}>
        {text}
      </Link>
    );
  } else if (linkType === "external") {
    return (
      <a href={link} target="_blank" onClick={onClick}>
        {text}
      </a>
    );
  }
};

export default MyLink;
