import React from "react";
import { Link } from "gatsby";
import { slug } from "../utils";

const Button = ({
  text,
  btnType,
  linkType,
  link,
  cls = "",
  border = "bordered-white",
  submit = false,
  disable = false,
}) => {
  if (linkType === "internal") {
    return (
      <Link
        to={slug(link)}
        className={`main-btn ${cls} ${
          btnType === "primary" ? "btn-yellow" : `bordered-btn ${border}`
        } `}
      >
        {text}
      </Link>
    );
  } else if (linkType === "external") {
    return (
      <a
        href={link}
        className={`main-btn ${cls} ${
          btnType === "primary" ? "btn-yellow" : `bordered-btn ${border}`
        } `}
        target="_blank"
      >
        {text}
      </a>
    );
  } else {
    return (
      <button
        className={`main-btn ${cls} ${
          btnType === "primary" ? "btn-yellow" : "bordered-btn bordered-white"
        } `}
        type={submit ? "submit" : "button"}
        disabled={disable}
      >
        {text}
      </button>
    );
  }
};

export default Button;
